import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import BaseCardListing from 'components/organisms/base-card-listing';
import HeadTitleDescription from 'components/organisms/head-title-description';
import { BaseCardType } from 'components/molecules/base-card';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb';
import Container from 'components/atoms/container';

type DataProps = {
  industriesPage: {
    industriesData: {
      titleTop: string;
      subtitleTop: string;
      servicesList: BaseCardType[];
    };
  };
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
};

const IndustriesHubPage = ({
  pageContext,
  data: {
    industriesPage: {
      industriesData: { servicesList, titleTop, subtitleTop },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <Container />
    <HeadTitleDescription showDots titleTop={titleTop} subtitleTop={subtitleTop}>
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescription>
    <BaseCardListing cards={servicesList} smallMarginContent showDescription="both" />
    <LetsTalkBanner {...letsTalkBanner} margin={`${theme.gap.xl} auto 0`} halfBg />
  </Layout>
);

export const query = graphql`
  query IndustriesHubPage($id: String, $commonComponentsSlug: String) {
    industriesPage: wpPage(id: { eq: $id }) {
      industriesData {
        titleTop
        subtitleTop
        servicesList {
          description
          heading
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
          icon {
            fieldGroupName
            name
            fieldGroupName
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default IndustriesHubPage;
