import styled from 'styled-components';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';
import Typography from 'components/atoms/typography';

export const StyledBg = styled.div<{ margin?: string; halfBg?: boolean; reverseHalfBg?: boolean }>`
  ${breakpoint.md} {
    margin: ${({ margin }) => margin || '0 auto'};
    padding: ${({ halfBg, theme }) => halfBg && `0 0  ${theme.gap.xl} 0`};
    background: ${({ halfBg, theme }) =>
      halfBg &&
      `linear-gradient(${theme.colors.transparent} 50%, ${theme.colors.grayscaleGray0}  50%)`};
    background: ${({ reverseHalfBg, theme }) =>
      reverseHalfBg &&
      `linear-gradient(${theme.colors.grayscaleGray0} 50%,${theme.colors.transparent} 50%)`};
  }
`;

export const StyledWrapper = styled.div<{}>`
  width: 100%;
  max-width: ${({ theme }) => theme.wrapper};
  margin: 0 auto;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.complementary1};
  padding: ${({ theme }) => theme.gap.xl};
  width: 100%;
  max-width: ${({ theme }) => theme.wrapper};
  position: relative;
  padding-inline: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    align-items: center;
    text-align: center;
    padding: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.sm};
  max-width: 655px;
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.mdl};
  max-width: 640px;
`;

export const StyledDotsGridOne = styled(DotsGrid)`
  bottom: -34px;
  right: 12px;

  ${breakpoint.md} {
    top: -21px;
    right: 24px;
  }
`;

export const StyledDotsGridTwo = styled(DotsGrid)`
  top: -21px;
  right: -81px;
  display: none;

  ${breakpoint.lg} {
    display: block;
  }
`;

export const StyledDotsGridThree = styled(DotsGrid)`
  top: 21px;
  left: 18px;

  ${breakpoint.md} {
    left: ${({ theme }) => theme.gap.mdl};
  }
`;
