import React from 'react';

import Button, { ButtonType } from 'components/atoms/button';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledBg,
  StyledContent,
  StyledDescription,
  StyledDotsGridOne,
  StyledDotsGridThree,
  StyledDotsGridTwo,
  StyledTitle,
  StyledWrapper,
} from './lets-talk-banner.styles';

export type LetsTalkBannerType = {
  heading: string;
  description: string;
  linkButton: ButtonType;
  className?: string;
  margin?: string;
  halfBg?: boolean;
  reverseHalfBg?: boolean;
};

const LetsTalkBanner = ({
  heading,
  description,
  linkButton,
  className,
  margin,
  halfBg,
  reverseHalfBg,
}: LetsTalkBannerType) => {
  const { isMdUp } = useScreenService();
  return (
    <StyledBg margin={margin} halfBg={halfBg} reverseHalfBg={reverseHalfBg}>
      <StyledWrapper className={className}>
        <StyledContent>
          <StyledTitle variant="title1" color="white" component="h2">
            {heading}
          </StyledTitle>
          <StyledDescription variant="body2" color="whiteAlpha72" html={description} />
          <Button {...linkButton} width="max-content" />
          <StyledDotsGridOne
            width={isMdUp ? 3 : 3}
            height={isMdUp ? 2 : 7}
            color="complementary3"
          />
          {isMdUp ? <StyledDotsGridTwo width={2} height={7} color="complementary3" /> : null}
          <StyledDotsGridThree
            width={isMdUp ? 2 : 6}
            height={isMdUp ? 7 : 2}
            color={isMdUp ? 'whiteAlpha40' : 'whiteAlpha72'}
          />
        </StyledContent>
      </StyledWrapper>
    </StyledBg>
  );
};

export default LetsTalkBanner;
